import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'


const Container = styled.div`
position:relative;
width:100%;
height:20vh;
max-height:600px;

@media (min-width:900px) {
  height:25vw;
}
& > * {
  width:100%;
  height:100%;
  
}
`        

const Banner = ({data}) => (
  <Container>
    <PreviewCompatibleImage imageInfo={data.image} objectPosition="60% 10%"/>
  </Container>
)

Banner.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
  }),
}


export default Banner