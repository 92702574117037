import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import RedButton from './RedButton'


const Container = styled.div`
& > div {
  position:relative;
  padding:4%;
  width:100%;
  max-width:1400px;
  margin:auto;
  display:flex;
  flex-flow: row wrap;
}
width:100%;

`        

const Image = styled.div `
position:relative;
padding:0% 10%;
width:100%;
height:300px;

& >* {
    height:100%;
    width:100%;
}
@media(min-width:993px) {
  width:50%;
}
`
const TextSide = styled.div`
position:relative;
width:100%;
padding: 0% 10%;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
font-family:lato;
& h2 {
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:24px;
  color:var(--tmain);
  margin:0;
  display:none;
  @media(min-width:601px) {
    font-size:28px;
  }
}
& > p {
  margin-bottom:50px;
}

@media(min-width:993px) {
  width:50%;
  & h2 {
    display:block;
  }
}
margin-bottom:50px;
`
const Header = styled.h2`
font-family:Montserrat;
font-weight:700;
font-style:italic;
font-size:24px;
color:var(--tmain);
text-align:center;
width:100%;
display:block;

@media(min-width:993px) {
  display:none;
}
`
const Services = ({data}) => (
  <Container>
    <div>
    <Header>{data.header}</Header>
    <Image>
      <PreviewCompatibleImage imageInfo={data} objectFit="contain"/>
    </Image>
    <TextSide>
      <h2>{data.header}</h2>
      <ReactMarkdown>{data.text}</ReactMarkdown>
      <RedButton href={data.link}>weitere Infos</RedButton>
    </TextSide>
    </div>
  </Container>
)

Services.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    list: PropTypes.arrayOf( PropTypes.shape({
      text: PropTypes.string,
      image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
      link: PropTypes.string,
    })),
  }),
}


export default Services