import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const { useState } = React

const Container = styled.div`
position:relative;
width:100%;
height:auto;
margin:0 auto;

& > h2 {
  text-align:center;
  font-family:Montserrat;
  font-style:italic;
  color:var(--tmain);
  font-size:28px;
  margin-bottom:25px;
}
`

const SliderWrapper = styled.div`
position:relative;

#backward {
  left:0;
  transform:translateY(-50%) translateX(25%);
}

#forward {
  right:0;
  transform:translateY(-50%) translateX(-25%);
}

@media(min-width:601px){
  &:hover {
    #backward, #forward {
      opacity:1;
      user-select:auto;
    }
  }
}
`

const SliderContainer = styled.div`
overflow:hidden;
position:relative;
`

const StaticContainer = styled.figure`
display:none;
flex-direction:row;
justify-content:center;

margin:0;

@media(min-width:${props => props.slideElementWidth + 1}px){
  display:flex;
}
`

const Slider = styled.figure`
position:relative;
display:flex;
flex-direction:row;
flex-shrink:0;
width:${props => props.slideElementWidth}px;
left:${props => props.current}px;
margin:0;
padding:0;
transition:left 0.5s ease-in-out;

@media(min-width:${props => props.slideElementWidth + 1}px){
  display:none;
}
`

const SlideButton = styled.button`
position:absolute;
display:block;

top:50%;
width:40px;
height:40px;

border-radius:8px;
padding:0px 10px 2px 10px;
text-decoration:none;

font-family:Montserrat;
font-weight:700;
font-style:italic;
font-size:22px;

background-color:var(--tmain);
color:var(--twhite);
border:2px solid var(--tmain);
user-select:none;
opacity:0;

transition:all 0.2s;

&:hover {
  cursor:pointer;
  background-color:var(--twhite);
  color:var(--tmain);
}

@media(min-width:${props => props.slideElementWidth + 1}px){
  display:none;
}
`


const CarouselWithStatic = ({width,elementCount,children}) => {

  const [current, setCurrent] = useState(0);
  const [windowWidth, setWidth] = useState(0);
  const slideElementWidth = width*elementCount
  const slideRange = width*(elementCount-1)
  var timeOutFunctionId;
  var x0 = 0;
  var y0 = 0;
  
  React.useEffect(() => {
    window.onresize = function() {
      clearTimeout(timeOutFunctionId)
      timeOutFunctionId = setTimeout(function() { setCurrent(Math.min(0, Math.max(current, ((windowWidth-width)/2) - slideRange))); setWidth(window.innerWidth); }, 50)
    }
  })

  React.useEffect(() => {
    setWidth(window.innerWidth);
    setCurrent((window.innerWidth-width)/2);
  }, [])

  const next = () => {
    setCurrent(Math.max(current - width, ((windowWidth-width)/2) - slideRange))
  }

  const previous = () => {
    setCurrent(Math.min((windowWidth-width)/2, current + width))
  }

  const lockSwipe = (e) => {
    x0 = e.touches[0].clientX
    y0 = e.touches[0].clientY
  }

  const swipe = (e) => {
    let dx = e.changedTouches[0].clientX - x0
    let dy = e.changedTouches[0].clientY - y0
    if(dx>0&&Math.abs(dx)>Math.abs(dy)){
      previous()
    }
    else if(dx<0&&Math.abs(dx)>Math.abs(dy)){
      next()
    }
  }

  return(
    <Container>
      <SliderWrapper>
        <SliderContainer>
          <Slider onTouchStart={(e) => lockSwipe(e)} onTouchEnd={(e) => swipe(e)} width={width} slideElementWidth={slideElementWidth} current={current} windowWidth={windowWidth}>
            {children}
          </Slider>
          <StaticContainer slideElementWidth={slideElementWidth} windowWidth={windowWidth}>
            {children}
          </StaticContainer>
        </SliderContainer>
        <SlideButton id="backward" onClick={previous} slideElementWidth={slideElementWidth} windowWidth={windowWidth}>«</SlideButton>
        <SlideButton id="forward" onClick={next} slideElementWidth={slideElementWidth} windowWidth={windowWidth}>»</SlideButton>
      </SliderWrapper>
    </Container>
  )
};

CarouselWithStatic.propTypes = {
  width: PropTypes.string,
  margin: PropTypes.string,
  elementCount: PropTypes.string,
  children: PropTypes.object,
};

export default CarouselWithStatic;