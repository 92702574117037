import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import RedButton from './RedButton'
import IndexMap from './IndexMap'
import { useStaticQuery, graphql } from "gatsby"


const Container = styled.div`
position:relative;
width:100%;
margin:auto;
display:block;

text-align:center;
font-family:lato;
& h2 {
  
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:24px;

  color:var(--tmain);
  margin:40px 0 0 0;
  @media (min-width:601px) {
    font-size:28px;
  }
}
& a {
  margin:20px auto 40px auto;
}
`        
export default function IndexFeuerwehren({data}) {
  const data2 = useStaticQuery(graphql`
    query IndexFeuerwehrenQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "feuerwehren-page"}}) {
        frontmatter {
          
          feuerwehrenKarte {
            lat        
            lon        
            name        
            text        
          }
        }
      }
    }
  `)

  
  return (
    <Container>
      <h2>{data.header}</h2>
      <RedButton href={data.link}>alle anzeigen</RedButton>
      <IndexMap data = {data2.markdownRemark.frontmatter.feuerwehrenKarte}/>
      
    </Container>
  )
}


IndexFeuerwehren.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
    link: PropTypes.string,
  }),
}

