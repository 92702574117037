import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { IndexPageTemplate } from '../templates/index-template'

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const blog = []
  for (let i = 0; i<data.allMarkdownRemark.nodes.length; i++) {
    if(data.allMarkdownRemark.nodes[i].frontmatter.title!='') {
      blog.push(data.allMarkdownRemark.nodes[i].frontmatter)
    }
  }

  return (
    <IndexPageTemplate
      banner = { frontmatter.banner }
      intro = { frontmatter.intro }
      blogPreviewX = { frontmatter.blogPreviewX }
      aboutUs = { frontmatter.aboutUs }
      facharbeit = { frontmatter.facharbeit }
      abteilungen = { frontmatter.abteilungen }
      services = { frontmatter.services }
      indexFeuerwehren = { frontmatter.indexFeuerwehren }
      partner = { frontmatter.partner }
      foerdervereine = { frontmatter.foerdervereine }
      blog = { blog }
    />
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default IndexPage

export const indexPageQuery = graphql`
query IndexTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "index-page"}}) {
    frontmatter {
      
      banner {
        image
          {
          childImageSharp {
            gatsbyImageData(
              width: 800,
            )}
          }
        
      }
      intro {
        header        
        text        
      }
      blogPreviewX {
        header        
      }
      aboutUs {
        header        
        text        
        link        
        imageArray {
          text          
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
          link          
        }
      }
      facharbeit {
        header        
        subHeader        
        text        
        link        
        imageArray {
          text          
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
          link          
        }
      }
      abteilungen {
        header        
        list {
          text          
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
          link          
        }
      }
      services {
        header        
        list {
          text          
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
          link          
        }
      }
      indexFeuerwehren {
        header        
        text        
        link        
      }
      partner {
        header        
        partnerLogos {
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
        }
      }
      foerdervereine {
        header        
        text        
        link        
        image
          {
          childImageSharp {
            gatsbyImageData(
              width: 800,
            )}
          }
        
      }
    }
  }
  allMarkdownRemark(
    sort: { fields: [frontmatter___date], order: DESC }
    limit: 13
  ) {
    nodes {
      frontmatter {
        title        
        date        
        content        
        imageArray {
          discription          
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
        }
        tagArray {
          tag          
        }
      }
    }
  }
  

}
`