import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Arrow from "../icons/arrowwithtail.svg"
const { slugify, convertDate } = require("../../myutil")

const Container = styled.div`
position:relative;
width:90%;
max-width:1400px;
margin:0 auto;
& > h2 {
    font-family:Montserrat;
    font-style:italic;
    font-size:24px;
    text-align:center;
    color: var(--tmain);
}
& p {
    text-align:justify;
}

@media(min-with:601px) {
    & > h2 {
        font-size: 28px;
    }
}
`
const Tags = styled.div`
position:relative;
display:flex;
flex-flow: row wrap;
margin-top:15px;
& p {
    font-size: 14px;
    font-weight:400;
    padding: 6px 12px;
    background-color:#ddd;
    border-radius:100px;
    margin 4px 10px 3px 4px;
    font-family:lato;
}

`
const Line = styled.div`
position:relative;
width:100%;
height:1px;
background-color:#ddd;
margin-top:10px;
margin-bottom:20px;

`

const Blog = styled.div`
padding:0% 3%;
max-width:800px;
margin:auto;
font-family:lato;
& > p:first-child {
    color:var(--tmain);
    margin:0;
}
& > a {
    text-decoration:none;
    font-size:24px;
    font-weight:700;
    color:var(--tgrey);
    
}
`
const StyledAnchorLink = styled(AnchorLink)`
display:flex;
flex-direction:row;
align-items:center;
justify-content:end;
background-color: transparent;
border:none;
color: var(--tmain);
transition: 0.2s ease;
font-family: lato;
gap:10px;
& p { 
    font-size:16px; 
    color: var(--tmain);
}

& svg {
    & > * {
        fill:var(--tmain);
        
    }
    transform:rotate(180deg);
    margin-left:10px;
    
}
&:hover {
    cursor:pointer;
    & p {
        color:var(--tgrey-dark);
    }
    
    & svg {
        & > * {
            fill:var(--tgrey-dark);
        }
    }
}

`
const get100words = (text) => {
    const wordArray = text.split(" ");
    const maxWords = 46
    let temp = "";
    for( let i = 0; i<maxWords; i++) {
        if(i>=wordArray.length) {
            
            break;
        }
        temp = temp.concat(" ", wordArray[i])
    }
    if(wordArray.length > maxWords)
        temp = temp.concat(" ...");
    return temp
}
const BlogsPreview = ({data, data2}) => (
    <Container>
        <h2>{(data2 != null)?(data2.header):("")}</h2>
        {
        
        //handles the case that data is not passed for cms preview
        (Array.isArray(data))?(
            data.map((item,i) => 
            <Blog key = {i}>
                <p>{convertDate(item.date)}</p>
                <AnchorLink to={slugify(item.title)}>{item.title}</AnchorLink>
                <Tags>
                {   
                    (Array.isArray(item.tagArray))?(
                        item.tagArray.map((item2, ii) => 
                        <p key={ii}>{item2.tag}</p>
                        )
                    ):(<div></div>)
                }
                </Tags>
                <ReactMarkdown>{(typeof(item.content)=='string')?(get100words(item.content)):("")}</ReactMarkdown>
                <StyledAnchorLink to={slugify(item.title)}>
                    <Arrow></Arrow>
                    <p>weiter lesen</p>
                </StyledAnchorLink>
                <Line/>
            </Blog>
            )
        ):(
            <div>Blog Posts können in diesem Modus nicht angezeigt werden.</div>
        )
        }
        
        
    </Container>
)

BlogsPreview.propTypes = {
    data: PropTypes.arrayOf( 
        PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                slug: PropTypes.string,
                date: PropTypes.string,
                content: PropTypes.string,
                tagArray: PropTypes.arrayOf( PropTypes.shape({
                    tag: PropTypes.string,
                })),
            })
    }),
    )
}

export default BlogsPreview