import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { AnchorLink } from'gatsby-plugin-anchor-links'
import RedButton from '../components/RedButton'
import Carousel from './CarouselWithStatic'


const Container = styled.div`
position:relative;
width:100%;
margin:0 auto;

& > h2 {
    text-align:center;
    font-family:Montserrat;
    font-style:italic;
    color:var(--tmain);
    font-size:24px;
    margin:0;
    margin-bottom:25px;
    
    @media(min-width:601px) {
      font-size:28px;
    }
}
`

const SlideElement = styled.div`
position:relative;
width:${props => props.width}px;
margin:0 ${props => props.margin}px;

display:flex;
flex-direction:column;
align-items:center;
gap:15px;
`

const Image = styled(AnchorLink)`
position:relative;
width:${props => props.width}px;
height:${props => props.width}px;
margin:0;

& > * {
    width:100%;
    height:100%;
}
`


const CarouselStaticHeader = ({data,width,margin}) => {

  return(
    <Container>
      <h2>{data.header}</h2>
      <Carousel width={width} elementCount={data.list.length}>
      {
        data.list.map((item,i) => (
          <SlideElement key={i} width={width-(margin*2)} margin={margin}>
            <Image width={width-(margin*2)}>
              <PreviewCompatibleImage imageInfo={item}/>
            </Image>
            <RedButton href={item.link}>{item.text}</RedButton>
          </SlideElement>
        ))
      }
      </Carousel>
    </Container>
  )
}

CarouselStaticHeader.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    list: PropTypes.arrayOf( PropTypes.shape({
      text: PropTypes.string,
      image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
      link: PropTypes.string,
    })),
  }),
}


export default CarouselStaticHeader;