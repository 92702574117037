import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'


const Container = styled.div`
position:relative;
width:90%;
max-width:1400px;
margin:40px auto 0 auto;

& h2 {
  font-size:24px;
  color:var(--tmain);
  font-family:Montserrat;
  font-style:italic;
  font-weight:700;
  text-align:center;
}

text-align:justify;
font-family:Lato;
font-size:16px;

& p {
  max-width:700px;
  margin:0 auto;
}

@media(min-width:601px){
  & h2 {
    font-size:28px;
  }
}
`        

const Intro = ({data}) => (
  <Container>
    <h2>{data.header}</h2>
    <ReactMarkdown>{data.text}</ReactMarkdown>
  </Container>
)

Intro.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    text: PropTypes.string,
  }),
}


export default Intro