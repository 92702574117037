import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import RedButton from './RedButton'
import Arrows from '../icons/arrows.svg'
import { AnchorLink } from'gatsby-plugin-anchor-links'

const Container = styled.div`
position:relative;
width:100%;
max-width:1400px;
margin:auto;
display:flex;
flex-flow:row wrap;

`        
const TextSide = styled.div`
width:100%;
padding:3%;
display:flex;
flex-direction:column;
justify-content:center;
text-align:justify;
font-family:lato;

& > h2 {
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:28px;
  color:var(--tmain);
  margin-bottom:0;
}
& a {
  margin-top:10px;
  align-self:end;
}
@media(min-width:993px) {
  width:50%;
  padding:8%;
}
`
const ImageSide = styled.div`
padding:3%;
right:0px;
width:100%;
display:flex;
flex-direction:column;
align-items:start;
margin-bottom:50px;
& .image {
  display:none;
}
& .button {
  height:32px;
  margin:5px;
  display:flex;
  align-items:center;
  padding:4px 12px;
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.3);
  text-decoration:none;
  transition:02 ease;
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:16px;
  color:var(--tblack);
  &::after {
    margin-left:5px;
    content:"»";
    color:var(--tmain);
    font-size:22px;
    margin-right:8px;
  }
  &:hover{
    color:var(--tmain);
    background-color:var(--twhite);
    cursor:pointer;

    &::before {
        color:var(--tmain);
    }
}
  
}
@media(min-width:993px) {
  width:50%;
  flex-flow: row wrap;
  & .image {
    display:block;
  }
  & .button {
    display:none;
  }
  margin:auto;
}

`

const AnchorLinkStyled = styled(AnchorLink)`
margin:5px;
position:relative;
width:40%;
height:160px;

& >* {
  
  height:100%;
  width:100%;
}
& img {
  transition: 1s ease;
}
& p {
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:16px;
  color:var(--tgrey);
  height:auto;
  width:auto;
  position:absolute;
  bottom:0%;
  background-color:white;
  padding:4px 12px;
  box-shadow: 2px 1px 2px 2px rgba(0,0,0,0.7);
}
&:hover {
  & img {
    filter:brightness(0.7);
  }
}
`
const AboutUs = ({data}) => (
  <Container>
    <TextSide>
      <h2>{data.header}</h2>
      <ReactMarkdown>{data.text}</ReactMarkdown>
      <RedButton href = {data.link} >weitere Infos</RedButton>
    </TextSide>
    <ImageSide>
    {
      (Array.isArray(data.imageArray))?(
      data.imageArray.map((item, i) => 
      <AnchorLinkStyled key = {i} className="image" to={item.link}>
        <PreviewCompatibleImage imageInfo={item}/>
        <p>{item.text}</p>
      </AnchorLinkStyled>
      )
      ):(
        <div/>
      )
    }
    {
      (Array.isArray(data.imageArray))?(
      data.imageArray.map((item, i) =>
      <a key = {i} className="button" href="/">
        <p>{item.text}</p>
      </a>
      )
      ):(
        <div/>
      )
    }
    </ImageSide>
  </Container>
)

AboutUs.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    imageArray: PropTypes.arrayOf( PropTypes.shape({
      text: PropTypes.string,
      image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
      link: PropTypes.string,
    })),
  }),
}


export default AboutUs