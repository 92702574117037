import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import AboutUs from '../components/AboutUs'
import AboutUsV2 from '../components/AboutUsV2'
import Facharbeit from '../components/Facharbeit'
import Abteilungen from '../components/Abteilungen'
import Services from '../components/Services'
import IndexFeuerwehren from '../components/IndexFeuerwehren'
import Banner from '../components/Banner'
import Partner from '../components/Partner'
import BlogPreview from '../components/BlogPreview'
import Intro from '../components/Intro'
import Spacer from'../components/Spacer'
import CarouselStaticHeader from '../components/CarouselStaticHeader'

export const IndexPageTemplate = ({
  banner,
  intro,
  blogPreviewX,
  aboutUs,
  facharbeit,
  abteilungen,
  services,
  indexFeuerwehren,
  partner,
  foerdervereine,
  blog,
  isPreview,
}) => (
    <div>
        <Helmet title="KFV Altenkirchen" defer={false}/>
        <Layout activePageIndex={0}>
          <Banner data={banner}/>
          <Spacer height={50} heightS={0}/>
          <Intro data={intro}/>
          <Spacer height={80} heightS={40}/>
          <BlogPreview data={ blog } data2 = { blogPreviewX }/>
          <Spacer height={100} />
          <AboutUsV2 data = { aboutUs }/>
          <Spacer height={100} heightS={100} />
          <CarouselStaticHeader data = { abteilungen } width={240} margin={20}/>
          <Spacer height={100} />
          <AboutUsV2 data = { facharbeit } imageLeft/>
          <Spacer height={100} heightS={100} />
          <CarouselStaticHeader data = { services } width={180} margin={20}/>
          <Spacer height={100} />
          {
            (!isPreview)?(<IndexFeuerwehren data= { indexFeuerwehren }/>):(null)
            
          }
          <Spacer height={100} heightS={30}/>
          <Partner data={partner}/>
          <Services data = { foerdervereine }/>
        </Layout>
    </div>
)

IndexPageTemplate.propTypes = {
  banner: PropTypes.object,
  intro: PropTypes.object,
  blogPreviewX: PropTypes.object,
  aboutUs: PropTypes.object,
  facharbeit: PropTypes.object,
  abteilungen: PropTypes.object,
  services: PropTypes.object,
  indexFeuerwehren: PropTypes.object,
  partner: PropTypes.object,
  foerdervereine: PropTypes.object,
}