import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage';

const Container = styled.div`
position:relative;
width:100%;
height:400px;
margin:75px 0 0 0;

& > h2 {
    text-align:center;
    font-family:Montserrat;
    font-style:italic;
    color:var(--tmain);
    font-size:24px;
    margin-bottom:25px;
    
    @media(min-width:601px) {
        font-size:28px;
    }
}

@media(min-width:993px){
    margin:150px 0 0 0;
}
`

const SliderContainer = styled.div`
overflow:hidden;
`

const Slider = styled.figure`
position:relative;
display:flex;
flex-direction:row;
align-items:center;
width:${props => ((props.width*props.length)+(props.margin*props.length*2))*2}px;
margin:0;
left:0;
animation:20s slider infinite linear;

@keyframes slider {
    0% {
        left:0;
    }
    100% {
        left:-${props => (props.width*props.length)+(props.margin*props.length*2)}px;
    }
}

@media(min-width:${props => ((props.width*props.length)+(props.margin*props.length*2))+1}px){
    display:none;
}
`

const StaticContainer = styled.figure`
position:relative;
display:none;
flex-direction:row;
align-items:center;
justify-content:center;
width:100%;

@media(min-width:${props => ((props.width*props.length)+(props.margin*props.length*2))+1}px){
    display:flex;
}
`

const Image = styled.div`
position:relative;
width:${props => props.width}px;
margin:0 ${props => props.margin}px;
height:auto;
float:left;

& > * {
    width:100%;
    height:100%;
}
`

const Partner = ({data}) => {

    const width = 250
    const margin = 40

    return(
        <Container>
            <h2>{data.header}</h2>
            <SliderContainer>
                <Slider length={data.partnerLogos.length} width={width} margin={margin}>
                    {
                        data.partnerLogos.map((item,i) => (
                            <Image key={i} width={width} margin={margin}>
                                <PreviewCompatibleImage imageInfo={item} objectFit='contain'/>
                            </Image>
                        ))
                    }
                    {
                        data.partnerLogos.map((item,i) => (
                            <Image key={i} width={width} margin={margin}>
                                <PreviewCompatibleImage imageInfo={item} objectFit='contain'/>
                            </Image>
                        ))
                    }
                </Slider>
                <StaticContainer length={data.partnerLogos.length} width={width} margin={margin}>
                    {
                        data.partnerLogos.map((item,i) => (
                            <Image key={i} width={width} margin={margin}>
                                <PreviewCompatibleImage imageInfo={item} objectFit='contain'/>
                            </Image>
                        ))
                    }     
                </StaticContainer>
            </SliderContainer>
        </Container>
    )
};

Partner.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    partnerLogos: PropTypes.arrayOf( PropTypes.shape({
      image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
    })),
  }),
}


export default Partner