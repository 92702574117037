import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import RedButton from './RedButton'
import Arrows from '../icons/arrows.svg'
import { AnchorLink } from'gatsby-plugin-anchor-links'
import Carousel from './Carousel'

const Container = styled.div`
position:relative;
width:100%;
margin:auto;
display:flex;
flex-flow:row wrap;
@media(min-width:993px){
  max-width:1400px;
}
`        
const TextSide = styled.div`
margin:auto;
width:90%;
display:flex;
flex-direction:column;
justify-content:center;
text-align:justify;
font-family:lato;

& > h2 {
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:24px;
  color:var(--tmain);
  margin-bottom:20px;
  text-align:center;
}
& a {
  margin-top:10px;
  align-self:center;
  margin-bottom:26px;
}
@media (min-width:601px) {
  & h2 {
    text-size:28px;
  }
}
@media(min-width:993px) {
  order: ${props => props.isLeft?"2":"1"};
  width:50%;
  padding:8%;
  & a {
    align-self:end;
  }
}
`
const ImageSide = styled.div`
padding:3%;
right:0px;
width:100%;
display:none;
flex-direction:column;
align-items:start;
margin-bottom:50px;

& .button {
  height:32px;
  margin:5px;
  display:flex;
  align-items:center;
  padding:4px 12px;
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.3);
  text-decoration:none;
  transition:02 ease;
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:16px;
  color:var(--tblack);
  &::after {
    margin-left:5px;
    content:"»";
    color:var(--tmain);
    font-size:22px;
    margin-right:8px;
  }
  &:hover{
    color:var(--tmain);
    background-color:var(--twhite);
    cursor:pointer;

    &::before {
        color:var(--tmain);
    }
}
  
}
@media(min-width:993px) {
  display:flex;
  order: ${props => props.isLeft?"2":"1"};
  width:50%;
  justify-content:${props => props.isLeft?"start":"end"};
  flex-flow: row wrap;
  margin:auto;
}
`

const ImageWrapper = styled.div`
margin:5px;
position:relative;
width:40%;
height: 200px;
box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
`

const AnchorLinkStyled = styled(AnchorLink)`
position:absolute;
bottom:0;
width:100%;
height:auto;
display:flex;
align-items:flex-end;
text-decoration:none;
margin:0;

& > *:first-child {
  width:calc(100% - 50px);
  min-height:30px;
  
  background-color:white;
  font-family:montserrat;
  font-weight:bold;
  font-size:16px;
  color:var(--tblack);
  text-align:center;
  padding:6px 8px;
  margin:0;
}
& >*:last-child {
  width:50px;
  min-height:52px;
  height:100%;
  margin-bottom:20px;
  background-color:var(--tmain);
  color:var(--twhite);
  font-family:montserrat;
  font-weight:bold;
  text-align:center;
  font-size:40px;
  margin:0;
  transition: 0.2s ease;
  border: 2px solid var(--tmain);
}

&:hover {
 &>*:last-child {
   background-color:var(--twhite);
   color:var(--tmain);
   
 }
}
`
const Image = styled.div`
position:relative;
height:100%;
width:100%;
& >* {
  height:100%;
  width:100%;
}
`

const SlideElement = styled.div`
position:relative;
width:${props => props.width}px;
height:${props => props.height}px;
margin:0 ${props => props.margin}px;
box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
flex-shrink:0;
`


const AboutUsV2 = ({data,imageLeft}) => (
  <Container>
    <TextSide isLeft={imageLeft}>
      <h2>{data.header}</h2>
      <ReactMarkdown>{data.text}</ReactMarkdown>
      <RedButton href = {data.link} >weitere Infos</RedButton>
    </TextSide>
    <ImageSide isLeft = {!imageLeft}>
    {
      (Array.isArray(data.imageArray))?(
      data.imageArray.map((item, i) => 
      <ImageWrapper key = {i}>
        <Image className = "image">
          <PreviewCompatibleImage imageInfo={item}/>
        </Image>
        <AnchorLinkStyled to={item.link}>
          <p>{item.text}</p>
          <p>»</p>
        </AnchorLinkStyled>
      </ImageWrapper>
      )
      ):(
        <div/>
      )
    }
    </ImageSide>
    <Carousel width={340} elementCount={data.imageArray.length}>
    {
      (Array.isArray(data.imageArray))?(
        data.imageArray.map((item,i) => (
          <SlideElement width={300} height={250} margin={20}>
            <Image className = "image">
              <PreviewCompatibleImage imageInfo={item}/>
            </Image>
            <AnchorLinkStyled to={item.link}>
              <p>{item.text}</p>
              <p>»</p>
            </AnchorLinkStyled>
          </SlideElement>
        )
      )):(<div/>)
    }
    </Carousel>
  </Container>
)

AboutUsV2.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    imageArray: PropTypes.arrayOf( PropTypes.shape({
      text: PropTypes.string,
      image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
      link: PropTypes.string,
    })),
  }),
}


export default AboutUsV2